<template>
  <div class="step__block-wrapper">
    <div>
      <span>1/2 of the installation in the product price</span>
    </div>
    <div class="step__block-wrapper-right">
      <CheckboxInput v-model="isHaflInstallation" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, defineProps, computed, watch } from "vue";
import { useEstimateStore } from "@/stores/estimateStore";

import CheckboxInput from "../CheckboxInput.vue";
import { toRef } from "vue";

type HalfEstimateFieldProps = {
  installationSum: number;
  discountPercent: number;
};
const estimateStore = useEstimateStore();

const props = defineProps<HalfEstimateFieldProps>();

const isHaflInstallation = ref(false);

const discount = 100 - props.discountPercent;
const halfInstallationSum = computed(() => props.installationSum / 2);
const halfInstallationWithDiscount = computed(
  () => (halfInstallationSum.value / discount) * 100,
);

watch(
  () => isHaflInstallation.value,
  (_isHaflInstallation) => {
    estimateStore.estimate.info.isHaflInstallation = _isHaflInstallation;
    estimateStore.estimate.info.halfInstallation = _isHaflInstallation
      ? halfInstallationSum
      : toRef(0);
    estimateStore.estimate.info.halfInstallationWithDiscount =
      _isHaflInstallation ? halfInstallationWithDiscount : toRef(0);
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
.step__block-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  margin-top: 8px;
}

.step__block-wrapper-right {
  display: flex;
  align-items: center;
  column-gap: 12px;

  margin-right: 12px;
}

.step__block-wrapper-right input {
  width: 100px;
  height: 35px;
  border-radius: 20px;
  background: #fff;
  padding: 0 17px;
}
</style>
